// FORM
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control, .select2-search input[type="text"][disabled], .select2-search input[type="text"][readonly], fieldset[disabled] .select2-search input[type="text"] {
  background-color: #fff;
  border-color: #d5dbdb;
  border-width: 1px;
  color: #969696;
  cursor: default;
  opacity: 1;
}
.form-control {
  border-width: 1px;
  .placeholder(#2980b9);
  &.select {
    min-width: auto;
    -webkit-appearance: menulist;
  }
}

.input-group-addon {
  @media (max-width: 992px) {
    font-size: 0.85rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}

.select-voli-mese { width: 180px; }


select.form-control.select {
  height: 40px !important;
  text-align: left !important;
  margin: 0px;
  //border-radius: 6px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  & > .select2-choice {
    height: 40px !important;
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c7c7 !important;
  opacity: 1; /* Firefox */
}

::-webkit-input-placeholder {
  color: #c7c7c7 !important;
}
:-moz-placeholder {
  color: #c7c7c7 !important;
}
::-moz-placeholder {
  color: #c7c7c7 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c7c7c7 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #c7c7c7 !important;
}