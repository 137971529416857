@charset "utf-8";

@import "flat-ui";
@import "_cookie";
@import "component/_form";
@import "component/_ricerca_google";
@import "component/_to_top";
@import "component/_testata_fixed";
@import "_mixin";

html {
  background: url('@{path-website}img/bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  background-color: transparent !important;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  color: @nice-blue;
  &:before, &:after {
    display: block;
    height: 40px;
    content: "";
  }
}

a {
  color: #1fbadc;
  outline: none;
}

img {
  border: 0;
}

p {
  font-size: 14px;
}

.lightbox-padding img {
  padding: 5px 0;
}

.table {
  td, th {
    padding: 0.6rem;
  }
  td {
    color: #333333
  }
}

ul {
  list-style-type: square;
  li {
    list-style-type: square;
  }
}

.offerte-hp {
  background-color: @rosso-offerte;
  padding: 15px;
  img {
    width: 280px;
  }
}

.text-red {
  color: @rosso-offerte;
}

.badge-cuba-light {
  background-color: white;
  color: @nice-blue;
  margin: auto 5px;
}

.header {
  border: 0;
  width: 100%;
  margin: 0 auto 10px auto;
}

.whatsapp-link-mobile {
  display: none;
  @media (max-width: 576px) {
    display: block;
  }
}

.whatsapp-link {
  background-color: #159f0e;
  padding: 10px 15px !important;
  i {
    font-size: 20px;
  }
}

.hotel-star {
  white-space: nowrap;
}

.list-inline li {
  list-style-type: none;
}

.container.page {
  background-color: white;
  padding: 20px;
}

.foto_di_cuba img {
  margin-bottom: 20px;
}

.panel .table {
  margin-bottom: 0;
}

h3.panel-title {
  font-size: 14px;
}

.tabella-prezzi-bus, .tabella-prezzi-bus th {
  text-align: center;
}

.img-responsive, .img-fluid {
  display: block;
  margin: 0 auto;
}

.navbar {
  clear: both;
}

.breadcrumb, .breadcrumb a {
  color: #777;
  font-size: 12px !important;
  text-transform: uppercase;
}

.breadcrumb > .active {
  color: @nice-blue;
  font-weight: bold;
}

.card {
  margin: 6px auto;
  .card-header {
    h1, h2, h3, h4, h5 {
      margin: 0;
      font-size: 1.3em;
    }
  }
  .card-body .media {
    padding-bottom: 15px;
  }
  .table {
    margin-bottom: 0;
  }

}

.card-padding {
  .card-body {
    padding: 25px;
  }
}

.elenco-tab {
  .card {
    margin-bottom: 20px;
  }
}

.tabella-prezzi {
  .titolo {
    background-color: @nice-blue;
    color: #fff;
  }
}

/*--------------------------------------menu----------------------------*/

.menu-desktop .card {
  margin-top: 25px;
}

.menuCuba {
  .expand {
    position: relative;
    &:after {
      content: "\f078";
      font-family: FontAwesome, serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #fff;
      font-size: 13px;
      padding-right: 15px;
      position: absolute;
      top: 7px;
      right: 0;
    }
  }

  li {
    background-color: @nice-blue;
    margin-bottom: 5px;
    a {
      padding: 8px;
      cursor: pointer;
      display: block;
      color: white !important;
      text-decoration: none;
    }
    ul li {
      margin: 0;
      background-color: #019fc8;
    }
  }
  &.col-inverse {
    li {
      background-color: #FFCC99;
      a {
        color: @nice-blue !important;
      }
      ul li {
        margin: 0;
        background-color: #fedfc0;
      }
    }
  }
  &.col-offerte {
    li {
      background-color: @rosso-offerte;
      a {
        color: white !important;
      }
      ul li {
        margin: 0;
        background-color: #fedfc0;
      }
    }
  }
}

/*------------------------------------top------------------------------------------*/

.top-first-line {
  margin: -20px -20px 20px -20px;
  .topHeader {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
    font-size: 11px;
    color: #000000;
    width: auto !important;
    margin: 0;
    .menu {
      padding: 5px 0;
      &:hover {
        background-color: @nice-blue;
        a {
          color: white;
        }
      }
    }
    .tastoCarrello {
      background-color: @nice-blue;
      a {
        color: white;
      }
    }
  }
}

.testata-desktop {
  .banner-top {
    width: 100%;
  }
}

.testata-mobile {
  .navbar-header {
    width: 100%;
  }
  .navbar-toggler {
    float: right;
  }
  @media (max-width: 767px) {
    .slide-hotel img {
      max-height: 170px;
    }

    .logoCuba {
      max-width: 200px;
    }
  }
  .menu-mobile {
    li {
      //text-align: center;
      background: #fff;
      border-bottom: 4px solid #f8f9fa;
      text-transform: uppercase;
      &:last-child {
        border: none;
      }
      a {
        display: block;
        width: 100%;
        padding: 8px;
        font-weight: bold;
      }
      ul {
        border: 0px;
        border-left: 25px solid #f8f9fa;
        border-top: 4px solid #f8f9fa;
        padding: 0px;
        margin: 0px;
        li {
          font-weight: normal;
          font-size: 13px !important;
          a:before {
            font-family: FontAwesome;
            content: "\f054";
            display: inline-block;
            padding-right: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.card {
  .card-title {
    margin-top: 5px;
  }
  .btn {
    margin: auto 10px;
  }
  .btn-block {
    margin: 0px;
  }
}

.btn-link {
  color: #fff;
}

.btn-link:hover, .btn-link:focus {
  color: #1fbadc;
  text-decoration: underline;
  background-color: transparent;
}

.accordian ul {
  display: none;
  li a:hover {
    background: #FFCC99;
    border-left: 5px solid #fa9d1c;
    color: @nice-blue;
    text-decoration: none;
  }
  li.active ul {
    display: block;
  }
}

.list-unstyled li {
  list-style: none;
}

.slide-hotel img {
  max-height: 200px;
}

.navbar-light {
  background-color: #fff;
  padding: 0;
  margin: 0;
  .navbar-toggle {
    margin: 0;
  }
  li {
    list-style-type: none;
  }
  .bg-light {
    padding: 10px;
    .dropdown-menu {
      background-color: transparent;
    }
  }
}

.ui-datepicker {
  z-index: 2000 !important;
}

.slick-slider {
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .slide-hotel img {
    max-height: 165px;
  }
}

@media (max-width: 992px) {
  .slide-hotel img {
    max-height: 140px;
  }
}

@media (max-width: 767px) {
  .slide-hotel img {
    max-height: 170px;
  }
}

@media (max-width: 520px) {
  .slide-hotel img {
    max-height: 100px;
  }
}

.tabellavoli td {
  height: 35px;
  text-align: center !important;
}

.testo_chiaro {
  font-size: 11px;
  color: #f0f8d0;
}

.boxtitolo {
  border: 1px solid #cccccc;
  h1 {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;
    text-transform: uppercase;
  }
}

.areeBianche {
  clear: both;
  padding: 0;
  margin: 0;
  p.lead {
    font-size: 19px;
  }
}

a.btn-primary {
  color: white;
  h2 {
    margin-top: 9px;
  }
}

.panel-default > .panel-heading {
  color: #428bca;
}

.panel-default > .panel-heading:hover {
  color: #ff9a23;
}

.card {
  background-color: #fff;
}

.card-body {
  padding: 8px;
}

.btn {
  white-space: normal;
  &.btn-red-cuba {
    background-color: #e50f42;
  }
  &.btn-secondary {
    background-color: #FFCC99;
    color: #00649d;
    .muted {
      color: #00649d;
    }
  }
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: #006caa;
  border-top: 1px solid rgba(0, 0, 0, .125);
  a {
    color: #fff
  }
}

.muted {
  color: #cdcdcd;
}

.btn-group {
  .form-control.select {
    height: 38px;
    & > .select2-choice {
      height: 38px;
    }
  }
}

.btn-group-single {
  .form-control.select {
    border-radius: 6px !important;
  }
}

.btn-outline-light {
  border: 1px solid #f8f9fa;
}

.bg-info {
  background-color: #006CAD !important;
}

.btn-outline-light:hover, .btn-outline-light:focus {
  outline: none;
  color: #ef840a;
}

.media-body {
  text-align: justify;
}

.info-light {
  background-color: #e3eef8;
}

.bs-example {
  margin-right: 0;
  margin-left: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 20px;
}

.box-table {
  margin-right: 0;
  margin-left: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 5px;
}

.bs-prezzi table {
  width: 100%;
  tr th {
    border: 1px solid;
    background-color: #b6e4ff;
    color: @nice-blue;
    padding: 5px;
  }
}

.bs-prezzi table tr td, .bs-testo table tr td {
  border: 1px solid;
  color: @nice-blue;
  padding: 5px;
}

.bs-testo table tr td {
  border: 0;
}

.tooltip.top .tooltip-arrow {
  top: auto !important;
}

.table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
  background-color: #f4c077;
}

.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
  background-color: #5fa7e6;
}

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
  background-color: #76d576;
}

/*-------------------------------- footer ------------------------------*/

.footer {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  font-size: 11px;
  color: #000000;
  width: auto !important;
  margin: 0;
  overflow: hidden;
  > div {
    width: 100%;
  }

  .menu {
    background-color: @nice-blue;
    margin-top: 10px;
    padding: 10px 0;
    a {
      color: #fff;
    }
    &:hover {
      background-color: #FFCC99;
      a {
        color: @nice-blue;
      }
    }
  }
  td {
    font-size: 11px;
  }
  a {
    color: #000;
    &:hover {
      color: @nice-blue;
    }
  }
  &.footer-3 > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 500px) {
    &.last-footer {
      margin-bottom: 80px;
      padding: 0 20px;
    }
    &.footer-3 {
      padding-bottom: 50px;
    }
  }
}

.fa-map-marker {
  display: inline;
}

.boxPrezzo {
  transition: all .3s ease .15s;
  display: inline;
  &:hover .oldPrice {
    opacity: 1;
    text-decoration: none;
  }
  .oldPrice {
    transition: all .3s ease .15s;
    text-decoration: line-through;
    font-size: 10px;
    color: #999;
  }
  .newPrice {
    transition: all .3s ease .15s;
    font-size: 12px;
    opacity: 1;
  }
}

.costo.big {
  .oldPrice, .newPrice {
    font-size: initial
  }
}

.modal-dialog {
  width: 50%;
  margin: 30px auto;
}

.panel-body-reduce-pad .panel-body {
  padding: 4px;
}

.lightSlider li, .lSPager li {
  list-style-type: none
}

.modal-backdrop {
  opacity: .5;
}

.clt-carrello {
  .table {
    .fa-trash-o {
      color: #2980b9;
      cursor: pointer;
    }
    thead {
      font-size: 1.1em;
    }
    tfoot th {
      padding: .4rem;
      border-bottom: 1px solid #e9ecef;
    }
    .riga_totale {
      font-size: 1.1em;
      th {
        background-color: rgba(255, 204, 153, 0.38);
      }
    }
    .prezzo_totale {
      padding: .3rem;
    }
  }
  #accordion-carrello {
    h4 {
      display: inline-block;
      margin-top: 2px;
      a {
        color: #fff;
      }
    }
    .badge-primary {
      font-size: 12px;
      vertical-align: text-bottom;
      background-color: #ef840a;
      margin-right: 4px;
    }
    .card-body {
      padding: 20px;
      background-color: #ffffff;
      .form-group {
        margin: 10px auto;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal-dialog {
    width: 95%;
  }

  .container.page {
    padding: 0 15px;
  }

  body:before, body:after {
    height: 20px;
  }

  .breadcrumb li {
    display: block;
    float: none;
    &::before {
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f178" !important;
    }
    &:first-child::before {
      content: '' !important;
    }
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}

.shade-card {
  padding: 0;
  .row {
    margin: 0px;
  }
  .no-padding {
    padding: 0px;
  }
  .home-card {
    display: block;
    padding: 30px 20px;
    opacity: 1.0;
    @media (max-width: 992px) {
      padding: 20px 10px;
    }
  }
  h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0px;
    @media (max-width: 1200px) {
      min-height: 35px;
      i {
        display: none;
      }
    }
    @media (max-width: 992px) {
      font-size: 12px;
      min-height: 30px;
    }
  }
  a:hover .home-card {
    //background-color: #f00;
    opacity: 0.95;
    .inner-shadow (@x: 0px, @y: 0px, @blur: 20px, @spread: 0, @alpha: 0.20)
  }

  .card-color-1 {
    background-color: #007DBC
  }
  .card-color-2 {
    background-color: @nice-blue
  }
  .card-color-3 {
    background-color: #006191
  }
  .card-color-4 {
    background-color: #005272
  }
  .card-color-5 {
    background-color: #FFCC99
  }
  .card-color-6 {
    background-color: #FFA655
  }
  .card-color-7 {
    background-color: #FC9038
  }
  .card-color-8 {
    background-color: #EA751C
  }
  &.img-fluid {
    padding-bottom: 20px;
  }
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(0, 108, 170);
  border-bottom: 3px solid rgba(0, 0, 0, .125);
  color: #fff;
  .card-title a {
    color: #fff;
  }
  a {
    color: #fff;
  }
}

.orange {
  color: @orange;
}

.fa.orange {
  font-weight: bold;
  padding: 0 10px 0 10px;
}

.list-group-item .fa.orange {
  font-weight: bold;
  padding: 0 0 0 10px;
}

.bg-primary {
  background-color: rgba(0, 108, 170, 0.9) !important;
}

/* social-icons ------------------------------------------------------*/
.social-footer {
  .img-fluid {
    display: inline-block;
  }
}

//override ipages
body.iframe:before, body.iframe:after {
  content: none;
}
.ipgs-theme-default .ipgs-stage {
   background-color: transparent;
   box-shadow: none;
}
.popup-catalogo {
  .mfp-content {
    max-width: 98%;
    max-height: 97%;
    overflow: hidden;
  }
  .mfp-iframe-holder {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .mfp-iframe-scaler {
    height: 100%;
  }
  .mfp-iframe-holder .mfp-close {
    top: -5px;
    right: 0px;
  }
}

.chooseTipologiaAdv {
  button {
    width: 100%;
    background-color: #e9ecef;
    color: #777;
    &:hover {
      color: black;
    }
  }
}

.badge-inverse {
  background-color: #FFCC99 !important;
  color: #006CAA !important;
}