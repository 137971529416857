.testata-fixed {
  opacity: 0;
  -webkit-transition: opacity .2s .2s;
  -moz-transition: opacity .2s .2s;
  transition: opacity .2s .2s;


  &.testata-visible {
    opacity: 0.95;
    top: 0px;
  }
  z-index: 1040;
  display: inline-block;
  height: auto;
  width: 100%;
  position: fixed;
  top: -100px;

  .container {
    background-color: #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  }
  .link-logo {
    max-width: 200px;
    float: left;
    .logo {
      display: inline-block;
      padding: 15px 0;
    }
  }
  .links {
    margin: 33px 0;
    font-size: 15px;
    a {
      padding: 0 10px;
      i { color: #ddd; padding-right: 5px; }
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}

