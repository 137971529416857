/* ricerca google ------------------------------------------------------*/
.boxSearch {
  border: 2px solid @nice-blue;
  margin-top: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input.gsc-search-button, input.gsc-search-button:hover, input.gsc-search-button:focus {
  -webkit-box-shadow: none;
  -moz-box-sizing: content-box;
  box-shadow: none;
}

input.gsc-input, .gsc-input-box, .gsc-input-box-hover, .gsc-input-box-focus {
  box-sizing: content-box;
  line-height: normal;
}

.gsc-input-box input[type="text"], .gsc-input-box input[type="text"]:focus, .gsc-input-box input[type="text"]:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
}

input.gsc-input, .gsc-input-box, .gsc-input-box-hover, .gsc-input-box-focus, .gsc-search-button {
  box-sizing: content-box;
  line-height: normal;
}

.gsc-tabsArea div {
  /*By default, Google custom search CSS sets it as AUTO, which shows scroll bars*/
  overflow: visible;
}