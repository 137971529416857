#cookie {
  background-color: #FFF !important;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.95;
  z-index: 100000;
  p {
    padding: 10px;
    margin: 0;
    font-family: arial, sans-serif;
    font-size: 11px !important;
    font-weight: bold !important;
    color: #000 !important;
    text-align: center;
  }
}

.tasto {
  background-color: #4D90FE;
  border: 1px solid #3079ED;
  border-radius: 2px;
  color: #FFF !important;
  cursor: default;
  display: inline-block;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-right: 0;
  min-width: 26px;
  outline: 0 none;
  padding: 0 8px;
  text-align: center;
  white-space: nowrap;
  &:hover {
    background-color: #fff;
    color: #4D90FE !important;
  }
}